<template>
  <layout drawerShow>
    <template #nav>
      <nav-exports/>
    </template>
    <template #drawer>
      <v-form>
        <v-col cols="12" class="pr-1">
          <v-select clearable v-model="filters.siteIds" multiple :items="sites" item-value="id" item-text="name"
                    label="Site" hide-details></v-select>
        </v-col>
        <v-col cols="12" class="pr-1">
          <v-select clearable v-model="filters.destinationIds" multiple :items="destinations" item-value="id"
                    item-text="name" label="Destination" hide-details></v-select>
        </v-col>
        <v-subheader light class="pa-0 ma-0">Réservation</v-subheader>
        <v-row>
          <v-col class="pr-1" cols="6">
            <smart-picker
                v-model="filters.start"
                :stopDate="filters.stop"
                format="yyyy-MM-dd"
                :inputOpts="{ label: $t('begin') }"
            />
          </v-col>
          <v-col cols="6">
            <smart-picker
                v-model="filters.stop"
                :startDate="filters.start"
                format="yyyy-MM-dd"
                :inputOpts="{ label: $t('end') }"
            />
          </v-col>
        </v-row>
        <smart-btn
            class="mt-5"
            block
            primary
            @click.native="search"
            :loading="loading"
        >Rechercher</smart-btn
        >
      </v-form>
    </template>
    <template #content>

      <v-card class="ma-4">
        <v-data-table
            :headers="headers"
            :items="itemsFiltered"
            :loading="loading"
            no-data-text="-"

            :footer-props="{
              'items-per-page-options': rowsPerPageItems,
              'items-per-page-text': 'Lignes par page',
            }"
            class="tbl"
        >

          <template #item.booking.bookingDate="{item}">
            {{ _parseAndFormatDate(item.booking.bookingDate, "yyyy-MM-dd", "dd/MM/yyyy") }}
          </template>

          <template #item.booking.vf="{item}">
            <span :class="[item.booking.vf !== item.inqom.totalVente ? 'bold red--text' : '']">{{
                item.booking.vf
              }}</span>
          </template>
          <template #item.inqom.totalVente="{item}">
            <span :class="[item.booking.vf !== item.inqom.totalVente ? 'bold red--text' : '']">{{
                item.inqom.totalVente
              }}</span>
          </template>
          <template #item.venteDiff="{item}">
            <span :class="[item.booking.vf !== item.inqom.totalVente ? 'bold red--text' : '']">{{
                Math.abs(item.inqom.totalVente - item.booking.vf)
              }}</span>
          </template>

          <template #item.booking.haReel="{item}">
            <span :class="[item.booking.haReel !== item.inqom.totalAchat ? 'bold red--text' : '']">{{
                item.booking.haReel
              }}</span>
          </template>
          <template #item.inqom.totalAchat="{item}">
            <span :class="[item.booking.haReel !== item.inqom.totalAchat ? 'bold red--text' : '']">{{
                item.inqom.totalAchat
              }}</span>
          </template>
          <template #item.achatDiff="{item}">
            <span :class="[item.booking.haReel !== item.inqom.totalAchat ? 'bold red--text' : '']">{{
                Math.abs(item.inqom.totalAchat - item.booking.haReel)
              }}</span>
          </template>

          <template #item.details="{item}">
            <v-btn small @click="showDetails(item)" text>...</v-btn>
          </template>

        </v-data-table>
      </v-card>

      <smart-dialog v-model="dialogDetails" width="1200">
        <v-card v-if="dialogDetails" class="pa-2">
          <div>
          Achat
            <v-data-table :headers="detailHeaders"
                   :items="itemDetail.inqom.achatEntries"/>
          </div>
          <div>
            Vente
            <v-data-table :headers="detailHeaders"
                     :items="itemDetail.inqom.venteEntries"/>
          </div>
        </v-card>
      </smart-dialog>

    </template>
  </layout>
</template>
<script>
import Layout from "@/views/Layout";
import NavExports from "./NavExports";
import {EventBus} from "@/components/commons/event-bus";
import {get, post} from "@/utils/api";
import booking from "@/views/booking/Booking.vue";
import {startOfMonth} from "date-fns";

export default {
  components: {
    Layout,
    NavExports,
  },
  created: function () {
    this.filters.start = this._formatDate(
        startOfMonth(new Date()),
        this.datePatternConfig.en.localDate
    );
    this.filters.stop = this._formatDate(
        new Date(),
        this.datePatternConfig.en.localDate
    );
    this.loadDestinations()
    this.loadSites()
  },
  data: () => ({

    headers: [
      {text: "#", value: "booking.id", width: "100px"},
      {text: "Date résa", value: "booking.bookingDate", width: "150px"},
      {text: "Vente non fact", value: "booking.vnf", width: "180px"},
      {text: "Vente fact", value: "booking.vf", width: "150px"},
      {text: "Vente Inqom", value: "inqom.totalVente", width: "150px"},
      {text: "Vente DIFF", value: "venteDiff", width: "150px"},
      {text: "Achat prev", value: "booking.haPrev", width: "150px"},
      {text: "Achat prov", value: "booking.haProv", width: "150px"},
      {text: "Achat fact", value: "booking.haReel", width: "150px"},
      {text: "Achat Inqom", value: "inqom.totalAchat", width: "150px"},
      {text: "Achat DIFF", value: "achatDiff", width: "150px"},
      {text: "", value: "details"},
    ],
    detailHeaders: [
        {text: "#", value: "Id", width: "100px"},
        {text: "Compte", value: "AccountNumber", width: "100px"},
        {text: "Label", value: "Label"},
        {text: "Débit", value: "DebitAmount", width: "100px"},
        {text: "Crédit", value: "CreditAmount", width: "100px"},
        {text: "Currency", value: "Currency", width: "100px"},
    ],
    items: [],
    itemDetail: null,
    dialogDetails: false,
    loading: false,
    filters: {
      siteIds: [4],
      destinationIds: [],
      start: null,
      stop: null
    },
    sites: [],
    destinations: [],
    rowsPerPageItems: [{text: "Tous", value: -1}, 50, 200],
    pagination: {
      sortBy: "bookingDate",
    },
  }),
  computed: {
    booking() {
      return booking
    },
    itemsFiltered() {
      return this.items.filter(i => !i.good)
    }
  },
  methods: {
    search() {
      this.loading = true;
      post("/api/accounting/export/check-inqom", this.filters)
          .json((data) => {
            this.items = data;
          })
          .catch((e) => {
            EventBus.$emit("toast", {
              color: "red",
              text: "Une erreur est survenue",
              exception: e,
            });
          })
          .then(() => {
            this.loading = false;
          });
    },
    showDetails(item) {
      this.itemDetail = item
      this.dialogDetails = true
    },
    async loadDestinations() {
      return get("/api/search/destinations-taxs")
          .json((destinations) => {
            this.destinations = destinations
            //this.searchCtx.destinationId = destinations.find(d => d.id == 18).id;

          })
    },
    async loadSites() {
      return get("/api/search/sites")
          .json((sites) => {
            this.sites = sites
          })
    },
  },
};
</script>
